import axios from "axios";
import { getCookie, removeCookie } from "goi_common";

export const Axios = axios.create({
  withCredentials: true,
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
});

function redirectToLogin() {
  removeCookie("teamjang_token");
  if (typeof window !== "undefined") {
    if (window.location.pathname !== "/login/") {
      window.location.href = "/login/";
    }
  }
}
Axios.interceptors.request.use(
  async (config) => {
    const token = getCookie("teamjang_token");
    if (token) {
      axios
        .get(`/api/superusercheck/?token=${token}`)
        .then((res) => {
          return Promise.resolve();
        })
        .catch((err) => {
          console.log("TT", err);
          redirectToLogin();
          return Promise.reject(config);
        });
    } else {
      redirectToLogin();
      return Promise.reject(config);
    }
    return config;
  },
  async (err) => {
    console.log("TTS", err);
    redirectToLogin();
    return Promise.reject(err);
  },
);
Axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalRequest = err.config;
    console.log(err);

    if (err.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await Axios.post("/users/refresh/");
        return Axios(originalRequest);
      } catch {}
    }

    return Promise.reject(err);
  },
);

export const AdminAxios = axios.create({
  withCredentials: true,
  baseURL: process.env.NEXT_PUBLIC_ADMIN_API_URL,
  params: {
    is_admin: true,
  },
});

let retry_count = 0;

AdminAxios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalRequest = err.config;

    if (err.response?.status === 401 && !originalRequest._retry && retry_count < 2) {
      originalRequest._retry = true;

      retry_count += 1;
      try {
        await AdminAxios.post("/users/refresh/");
        return AdminAxios(originalRequest);
      } catch {
        // 401
        // const redirectTo = `${location.pathname}${location.search}`;
        // window.location.href = `/login?redirectTo=${encodeURIComponent(redirectTo)}`;
      }
    }

    return Promise.reject(err);
  },
);
