import { OverlayProvider } from "@toss/use-overlay";
import { ThemeProvider } from "@emotion/react";
import type { AppProps } from "next/app";
import { RecoilRoot } from "recoil";
import "@/styles/portal.css";
import "@/styles/reset.css";
import "@/styles/theme/font.css";

import { ChakraProvider } from "@chakra-ui/react";

import AOS from "aos";
import "aos/dist/aos.css";
import { chakraTheme, GlobalStyle, theme } from "goi_common";
import Head from "next/head";
import { ReactElement, ReactNode, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import "slick-carousel/slick/slick.css";
import Layout from "@/components/Layout";
import { NextPage } from "next";
import { DialogRoot } from "@/components/Dialog/DialogRoot";
import PreviewServiceDialogRoot from "@/components/Packages/PreviewServiceDialogRoot";
import AuthProvider from "@/containers/LoginContainer/components/AuthProvider";

declare global {
  interface Window {
    kakao: any;
    naver: any;
    hackleClinet: any;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 0,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const handleResize = () => {
    const vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const Components = (
    // <HackleProvider hackleClient={HackleClient} supportSSR>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ChakraProvider resetCSS={true} theme={chakraTheme}>
        <QueryClientProvider client={queryClient}>
          <RecoilRoot>
            <AuthProvider>
              <OverlayProvider>
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </OverlayProvider>
            </AuthProvider>
            <DialogRoot />
            <PreviewServiceDialogRoot />
          </RecoilRoot>
        </QueryClientProvider>
      </ChakraProvider>
    </ThemeProvider>
    // </HackleProvider>
  );

  return (
    <>
      <Head>
        <title>{pageProps.pageOgTitle ? pageProps.pageOgTitle : "장례의 모든 것, 고이"}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      {Components}
    </>
  );
}

export default MyApp;
