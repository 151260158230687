import { getAdminInit } from "@/apis/admin";
import { queryKeys, setCookie } from "goi_common";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useQuery } from "react-query";

interface Props {
  children: JSX.Element;
}

const PUBLIC_PATH = ["/login"];

const AuthProvider = ({ children }: Props) => {
  const router = useRouter();
  const isPublicPath = PUBLIC_PATH.includes(router.pathname);

  const { data, isLoading } = useQuery(queryKeys.adminUser, getAdminInit, {
    enabled: !isPublicPath,
  });

  const isAuthenticated = isPublicPath || data;
  useEffect(() => {
    if (data) {
      setCookie("teamjang_user", data);
    }
  }, [data]);
  useEffect(() => {
    const needRedirectToLogin = !isLoading && !isAuthenticated;

    if (needRedirectToLogin) {
      router.push({ pathname: "/login", query: { redirectTo: router.asPath } });
    }
  }, [isAuthenticated, router, isLoading]);

  if (isAuthenticated) {
    return children;
  }

  return null;
};

export default AuthProvider;
